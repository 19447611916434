import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import 'default-passive-events' // 引入会影响功能

// 引入 element-plus UI组件框架
import ElementPlus, { ElLoading } from 'element-plus'
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/dark/css-vars.css'
import './assets/css/cus-style-dark.css'
import './assets/css/cus-style-bright.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import i18n from './language/Language.js'
import { menuChange, imgInfoByPc } from './utils/system/qtCall'
// 引入 UserInfoGlobal 自定义的用户信息实体类
import UserInfoGlobal from './utils/service/UserInfoGlobal'

// 引入 js-cookie 管理cookie的插件
import Cookies from 'js-cookie'

// 引入 base 自定义样式
import './assets/css/base.css'

// 引入主题配置
import './utils/theme/themeStyle.css'
import './utils/theme/themeStyle.js'

// 引入适配配置
import './utils/system/rem.js'

// 引入 bootstrap UI框架
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'

// 设置Cookies的域（同一域名下不同子域名之间共享用户登录信息）
Cookies.domain = window.SITE_CONFIG.domain
const app = createApp(App)

// 注册element-plus所有图标
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

// 其他
app.use(router)
app.use(store)
app.use(ElementPlus)
app.use(i18n)
app.provide('$userInfoGlobal', UserInfoGlobal)
app.mount('#app')

// 过渡动画
function ElementLoading (text) {
  this.loading = null
  this.open = (options = {
    lock: true,
    text: text || '加载中',
    background: 'rgba(0, 0, 0, 0.7)'
  }) => {
    this.loading = ElLoading.service(options)
  }
  this.close = () => {
    this.loading && this.loading.close()
  }
}
window.$ElLoading = new ElementLoading()
window.menuChange = menuChange// qt功能切换
window.imgInfoByPc = imgInfoByPc// 图片更新
